'use strict';

angular.module('cpformplastApp')
  .directive('linechart', () => ({
    templateUrl: 'components/line-chart/line-chart.html',
    restrict: 'E',
    controller: 'LineChartController',
    controllerAs: 'linechart',
    scope: {
      height: '=height',
      margin: '=margin',
      content: '=content'
    }
  }));
